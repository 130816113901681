import i18n from 'i18n-js';

import { Tabs } from '../../utils';
import { isMasterAdmin, isTecSupport } from '../../../../../../utils/authUtils';

const i18nOpts = { scope: 'components.admin.companies.settings.tabsContent.workflow' };

export function getSettings() {
  const settings = [
    {
      id: 'reserveNowWorkflow',
      label: i18n.t('workflows.navTabs.index.reserveNowWorkflow', i18nOpts),
      containerId: 'reserveNowWorkflowContainer'
    },
    {
      id: 'verifiedWorkflow',
      label: i18n.t('workflows.navTabs.index.verifiedWorkflow', i18nOpts),
      containerId: 'verifiedWorkflowContainer'
    }
  ];

  if (isMasterAdmin() || isTecSupport()) {
    settings.push(...[
      {
        id: 'enableReserveNow',
        label: i18n.t('index.enableReserveNow', i18nOpts),
        containerId: 'enableReserveNowContainer'
      },
      {
        id: 'enableVerified',
        label: i18n.t('index.enableVerified', i18nOpts),
        containerId: 'enableVerifiedContainer'
      },
      {
        id: 'enabledPriceNegotiation',
        label: i18n.t('index.enablePriceNegotiation', i18nOpts),
        containerId: 'enabledPriceNegotiationContainer'
      },
      {
        id: 'enabledReports',
        label: i18n.t('index.enableReports', i18nOpts),
        containerId: 'enabledReportsContainer'
      },
      {
        id: 'enableEmbeddedReserve',
        label: i18n.t('index.enableEmbeddedReserveNow', i18nOpts),
        containerId: 'enableEmbeddedReserveContainer'
      },
      {
        id: 'embeddedReserveBaseUrl',
        label: i18n.t('index.embeddedReserveNowBaseUrl', i18nOpts),
        containerId: 'embeddedReserveBaseUrlContainer'
      },
      {
        id: 'enableIdVerification',
        label: i18n.t('index.enableIdVerification', i18nOpts),
        containerId: 'enableIdVerificationContainer'
      },
      {
        id: 'idVerificationProvider',
        label: i18n.t('index.idVerificationProvider', i18nOpts),
        containerId: 'idVerificationProviderContainer'
      },
      {
        id: 'enablePreApprovalVerification',
        label: i18n.t('index.enablePreApprovalVerification', i18nOpts),
        containerId: 'enablePreApprovalVerificationContainer'
      },
      {
        id: 'enableHomewise',
        label: i18n.t('index.enableHomewise', i18nOpts),
        containerId: 'enableHomewiseContainer'
      },
      {
        id: 'enableDemoModeForHomewise',
        label: i18n.t('index.enableDemoModeForHomewise', i18nOpts),
        containerId: 'enableDemoModeForHomewiseContainer'
      },
      {
        id: 'bankVerificationProvider',
        label: i18n.t('index.bankVerificationProvider', i18nOpts),
        containerId: 'bankVerificationProviderContainer'
      },
      {
        id: 'enableContactPhoneRequired',
        label: i18n.t('index.phoneNumberMandatory', i18nOpts),
        containerId: 'enableContactPhoneRequiredContainer'
      },
      {
        id: 'enablePickingALot',
        label: i18n.t('index.enableLotSelectionFirst', i18nOpts),
        containerId: 'enablePickingALotContainer'
      },
      {
        id: 'enableSampleData',
        label: i18n.t('index.enableDemoSampleData', i18nOpts),
        containerId: 'enableSampleDataContainer'
      },
      {
        id: 'enableAirMilesForReserveNow',
        label: i18n.t('index.enableAirMilesForReserveNow', i18nOpts),
        containerId: 'enableAirMilesForReserveNowContainer'
      },
      {
        id: 'enableAirMilesForVerified',
        label: i18n.t('index.enableAirMilesForVerified', i18nOpts),
        containerId: 'enableAirMilesForVerifiedContainer'
      },
      {
        id: 'allowSaveHomeWithoutVerification',
        label: i18n.t('index.allowSaveHomeWithoutVerification', i18nOpts),
        containerId: 'allowSaveHomeWithoutVerificationContainer'
      }
    ]);
  }

  return settings.map((setting) => ({
    ...setting,
    tabId: Tabs.WORKFLOW
  }));
}
