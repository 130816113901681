import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { Spinner, Table } from 'reactstrap';
import momentTz from 'moment-timezone';

import { fetchMasterAdminMetricsByBuilder } from './utils';
import ListEmpty from '../../../common/ListEmpty';
import { SortOrders } from '../../../../constants';
import setSortFilter from '../../../../utils/sortUtils';
import SortableColumnHeader from '../../../common/SortableColumnHeader';
import SecureStore from '../../../../utils/secureStore';

const i18nOpts = { scope: 'components.admin.masterAdminDashboard.visitorLeadsTable.index' };

const DEFAULT_SORT = { field: 'companyName', direction: SortOrders.ASC };

const VisitorLeadsTable = ({ customDateRange }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [sort, setSort] = useState(DEFAULT_SORT);

  const loadMetrics = () => {
    setLoading(true);

    const timezone = momentTz.tz.guess();
    const filter = {
      customDateRange,
      timezone
    };
    const variables = { filter, sort };
    fetchMasterAdminMetricsByBuilder(variables)
      .then((items) => setData(items))
      .finally(() => setLoading(false));
  };

  const onCompanyClick = (company) => {
    SecureStore.setCurrentCompanyId(company.id);
    window.location = '/admin/overview';
  };

  const onSortChange = (field) => {
    const sortFilter = setSortFilter(field, sort);
    setSort(sortFilter);
  };

  useEffect(() => {
    loadMetrics();
  }, [customDateRange, sort]);

  return (
    <div className="mb-4">
      {loading ? (
        <div className="text-center my-5"><Spinner size="lg" /></div>
      ) : (
        <>
          <ListEmpty loading={loading} items={data} />

          {data.length > 0 && (
            <Table responsive bordered className="mt-2">
              <thead>
                <tr>
                  <SortableColumnHeader
                    field="companyName"
                    label={i18n.t('company', i18nOpts)}
                    onPress={() => onSortChange('companyName')}
                    sort={sort}
                  />
                  <SortableColumnHeader
                    field="visitorsCount"
                    label={i18n.t('visitors', i18nOpts)}
                    onPress={() => onSortChange('visitorsCount')}
                    sort={sort}
                  />
                  <SortableColumnHeader
                    field="leadsCount"
                    label={i18n.t('newLeads', i18nOpts)}
                    onPress={() => onSortChange('leadsCount')}
                    sort={sort}
                  />
                  <SortableColumnHeader
                    field="returningLeadsCount"
                    label={i18n.t('returningLeads', i18nOpts)}
                    onPress={() => onSortChange('returningLeadsCount')}
                    sort={sort}
                  />
                  <SortableColumnHeader
                    field="reservationStartedCount"
                    label={i18n.t('reserveStarts', i18nOpts)}
                    onPress={() => onSortChange('reservationStartedCount')}
                    sort={sort}
                  />
                  <SortableColumnHeader
                    field="reservationCompletedCount"
                    label={i18n.t('reserveCompletes', i18nOpts)}
                    onPress={() => onSortChange('reservationCompletedCount')}
                    sort={sort}
                  />
                  <SortableColumnHeader
                    field="verificationStartedCount"
                    label={i18n.t('verifiedStarts', i18nOpts)}
                    onPress={() => onSortChange('verificationStartedCount')}
                    sort={sort}
                  />
                  <SortableColumnHeader
                    field="verificationCompletedCount"
                    label={i18n.t('verifiedCompletes', i18nOpts)}
                    onPress={() => onSortChange('verificationCompletedCount')}
                    sort={sort}
                  />
                </tr>
              </thead>
              <tbody>
                {data.map((d) => (
                  <tr key={`visitor-leads-${d.company?.id}`}>
                    <td>
                      <div
                        role="button"
                        aria-hidden
                        className="col-primary-1 text-decoration-underline"
                        onClick={() => onCompanyClick(d.company)}
                      >
                        {d.company.name}
                      </div>
                    </td>
                    <td>{d.visitorsCount}</td>
                    <td>{d.leadsCount}</td>
                    <td>{d.returningLeadsCount}</td>
                    <td>{d.reservationStartedCount}</td>
                    <td>{d.reservationCompletedCount}</td>
                    <td>{d.verificationStartedCount}</td>
                    <td>{d.verificationCompletedCount}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </>
      )}
    </div>
  );
};

VisitorLeadsTable.propTypes = {
  customDateRange: PropTypes.objectOf(PropTypes.any).isRequired
};

VisitorLeadsTable.defaultProps = {};

export default VisitorLeadsTable;
